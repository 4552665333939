// src/components/TransitPinView.tsx
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ListHeader from './ListHeader';
import { IPin, IRecordingWithAuthor } from '../types';
import RecordingAccordion from './Accordions/RecordingAccordion';
import DraggablePinAccordion from './DraggablePinAccordion';

interface TransitPinViewProps {
  sortedPins: IPin[];
  transitRecordings: IRecordingWithAuthor[];
  isLoading: boolean;
  pinHeaders: string[];
  dragOverPinKey: string | null;
  isOrphanDragging: boolean;
  selectedProjectDetails: any;
  onEditPin: (pin: IPin) => void;
}

export const TransitPinView: React.FC<TransitPinViewProps> = ({
  sortedPins,
  transitRecordings,
  isLoading,
  pinHeaders,
  dragOverPinKey,
  isOrphanDragging,
  selectedProjectDetails,
  onEditPin,
}) => {
  return (
    <div className="split-view">
      <div className="pin-column">
        <ListHeader headers={pinHeaders} columnCount={pinHeaders.length} />
        <Droppable droppableId="droppablePins" direction="vertical">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={`dragstyles ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
              {sortedPins.length > 0
                ? sortedPins.map((pin, index) => (
                    <DraggablePinAccordion
                      pin={pin}
                      index={index}
                      isDragDisabled={isOrphanDragging || !selectedProjectDetails?.isSequentialEnabled}
                      dragOverPinKey={dragOverPinKey}
                      isOrphanDragging={isOrphanDragging}
                      selectedProjectDetails={selectedProjectDetails}
                      totalPins={sortedPins.length}
                      onEditPin={onEditPin}
                    />
                  ))
                : <div className="firstPin">Add your first Pin.</div>
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

      <div className="recording-column">
        <ListHeader headers={['Recording Name', 'Artist', 'File Name']} columnCount={3} />
        <Droppable droppableId="transitRecordings" type="RECORDING">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {isLoading ? (
                <div>Loading recordings...</div>
              ) : transitRecordings.length > 0 ? (
                transitRecordings.map((recording, index) => (
                  <Draggable key={recording.key} draggableId={recording.key} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <RecordingAccordion
                          key={recording.key}
                          pinKey={"transit"}
                          recordingKey={recording.key}
                          sourceView="PinList"
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <div>No recordings found.</div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default TransitPinView;