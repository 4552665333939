import React from 'react';
import { Box } from '@mui/material';

interface LogoHeaderProps {
  logoSrc?: string;
  altText?: string;
  width?: number;
  height?: number;
  marginTop?: string;
}

const LogoHeader: React.FC<LogoHeaderProps> = ({
  logoSrc = "/assets/icons/overhearlight.png",
  altText = "Logo",
  width = 120,
  height = 140,
  marginTop = '2rem'
}) => {
  return (
    <Box display="flex" justifyContent="center" mb={2}>
      <img 
        src={logoSrc} 
        alt={altText} 
        style={{ 
          width, 
          height, 
          marginTop 
        }} 
      />
    </Box>
  );
};

export default LogoHeader; 