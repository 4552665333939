import { FunctionComponent } from "react";
import { Button, Select, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { signOutUser } from '../services/authService';
import { IProject } from "../types";
import { useAdminNav } from "../hooks/useAdminNav";
import userStore from '../stores/UserStore';
import { useAppTheme } from '../hooks/useAppTheme';
import { Theme } from '../theme';

type AdminNavType = {
  imageDimensions?: string;
  projectName?: string;
  children?: React.ReactNode;
  userRole?: string;
  projects?: IProject[];
  selectedProject?: string;
  showProjectDropdown?: boolean;
  onProjectChange?: (project: string) => void;
};

type ThemedStyle<T> = (theme: Theme) => T;

export const AdminNav: FunctionComponent<AdminNavType> = ({
  imageDimensions,
  showProjectDropdown = true,
}) => {
  const navigate = useNavigate();
  const { themed } = useAppTheme();

  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole,
  } = useAdminNav();

  // Determine if we're in a school context
  const isSchool = userStore.currentOrganisation?.type === 'school';
  const dashboardLabel = isSchool ? 'Class Dashboard' : 'Groups Dashboard';

  // Check if we're in PinListView
  const isPinListView = window.location.pathname === '/pin-list-view';

  const handleSignOut = async () => {
    try {
      await signOutUser();
      localStorage.removeItem('selectedProject');
      navigate('/loginscreen');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const onTagsClick = () => navigate('/tags-view');
  const onPinsClick = () => navigate('/pin-list-view');
  const onProjectsClick = () => navigate('/project-view');
  const onFormsClick = () => navigate('/form-builder-view');
  const onDashboardClick = () => {
    const dashboardPath = userRole === 'moderator' ? '/moderator-dashboard' : '/org-dashboard';
    navigate(dashboardPath);
  };

  const shouldShowProjectDropdown = () => {
    if (!showProjectDropdown) return false;
    if (userRole === 'superAdmin' || userRole === 'moderator') return projects.length > 0;
    if (userRole === 'organisationAdmin') return isPinListView && projects.length > 0;
    return false;
  };

  const shouldShowProjectName = () => {
    if (userRole === 'superAdmin' || userRole === 'moderator') return false;
    if (userRole === 'organisationAdmin') return false;
    return true;
  };

  return (
    <div style={themed($container)}>
      <img src={imageDimensions} alt="" style={themed($logo)} />
      {shouldShowProjectDropdown() && (
        <Select
          value={selectedProject}
          onChange={(event) => onProjectChange(event.target.value as string)}
          displayEmpty
          style={themed($select)}
        >
          <MenuItem value="" disabled>
            Choose your project
          </MenuItem>
          {projects.map((project) => (
            <MenuItem key={project.project_id} value={project.project_id}>
              {project.projectName}
            </MenuItem>
          ))}
        </Select>
      )}
      {shouldShowProjectName() && (
        <h1 style={themed($projectName)}>{projectName}</h1>
      )}
      {(userRole === 'superAdmin' || userRole === 'moderator' || userRole === 'organisationAdmin') && (
        <Button
          style={themed($button)}
          color="primary"
          size="medium"
          variant="text"
          onClick={onProjectsClick}
        >
          {userRole === 'superAdmin' ? 'Super Admin' : 'Projects'}
        </Button>
      )}
      {(userRole === 'moderator' || userRole === 'organisationAdmin') && (
        <Button
          style={themed($button)}
          color="primary"
          size="medium"
          variant="text"
          onClick={onDashboardClick}
        >
          {dashboardLabel}
        </Button>
      )}
      <Button
        style={themed($button)}
        color="primary"
        size="medium"
        variant="text"
        onClick={onPinsClick}
      >
        Pins
      </Button>
      <Button
        style={themed($button)}
        color="primary"
        size="medium"
        variant="text"
        onClick={onTagsClick}
      >
        Tags
      </Button>
      <Button
        style={themed($button)}
        color="primary"
        size="medium"
        variant="text"
        onClick={onFormsClick}
      >
        Forms
      </Button>
      <Button
        style={themed($button)}
        color="secondary"
        size="medium"
        variant="text"
        onClick={handleSignOut}
      >
        Sign Out
      </Button>
    </div>
  );
};

const $container: ThemedStyle<React.CSSProperties> = ({ colors, spacing }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.md,
  padding: spacing.md,
  backgroundColor: colors.background,
  borderBottom: `1px solid ${colors.palette.border}`,
  height: '64px',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
});

const $logo: ThemedStyle<React.CSSProperties> = ({ spacing }) => ({
  height: '40px',
  flexShrink: 0,
});

const $select: ThemedStyle<React.CSSProperties> = ({ colors, spacing, borderRadius }) => ({
  minWidth: '200px',
  flexShrink: 0,
  backgroundColor: colors.palette.input.background,
  color: colors.palette.input.text,
  borderRadius: borderRadius.sm,
  '& .MuiSelectSelect': {
    backgroundColor: colors.palette.input.background,
    borderRadius: borderRadius.sm,
  },
  '& .MuiOutlinedInputNotchedOutline': {
    borderColor: colors.palette.border,
  },
  '&:hover .MuiOutlinedInputNotchedOutline': {
    borderColor: colors.primary,
  },
  '&.MuiFocused .MuiOutlinedInputNotchedOutline': {
    borderColor: colors.primary,
  },
});

const $projectName: ThemedStyle<React.CSSProperties> = ({ colors, typography }) => ({
  color: colors.text,
  fontSize: typography.h2.fontSize,
  fontWeight: typography.h2.fontWeight,
  margin: 0,
  marginRight: 'auto',
  whiteSpace: 'nowrap',
});

const $button: ThemedStyle<React.CSSProperties> = ({ colors, spacing }) => ({
  color: colors.text,
  marginLeft: spacing.sm,
  '&:hover': {
    backgroundColor: colors.palette.dropdown.hover,
  },
});

export default AdminNav;
