import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IPin } from '../types';
import PinAccordion from '../components/Accordions/PinAccordion';
import { CollectionLogic } from '../types/Project';
import { calculateIndentationLevel } from '../utilities/pinOrderUtils';

interface DraggablePinAccordionProps {
  pin: IPin;
  index: number;
  isDragDisabled: boolean;
  dragOverPinKey: string | null;
  isOrphanDragging: boolean;
  selectedProjectDetails: {
    collectionLogic?: CollectionLogic;
  };
  totalPins: number;
  onEditPin: (pin: IPin) => void;
}

const DraggablePinAccordion: React.FC<DraggablePinAccordionProps> = ({
  pin,
  index,
  isDragDisabled,
  dragOverPinKey,
  isOrphanDragging,
  selectedProjectDetails,
  totalPins,
  onEditPin,
}) => {
  const indentationLevel = calculateIndentationLevel(pin.pinOrder);
  const indentationClass = `indented-level-${indentationLevel}`;

  return (
    <Draggable
      key={pin.pinKey}
      draggableId={pin.pinKey}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => {
        const style = provided.draggableProps.style;
        let newY = '0px';
        if (style?.transform) {
          const match = /translate\(([^,]*), ([^)]*)\)/.exec(style.transform);
          newY = match ? match[2] : '0px';
        }

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...style,
              transform: `translate(0px, ${newY})`
            }}
            className={`dragstyles ${indentationClass} ${
              index === 0 ? "first-accordion" :
                index === totalPins - 1 ? "last-accordion" : ""
            }`}
          >
            <PinAccordion
              key={`${pin.pinKey}-${pin.pinOrder?.id}.${pin.pinOrder?.branch}`}
              pinKey={pin.pinKey}
              dragHandleProps={provided.dragHandleProps}
              collectionLogic={selectedProjectDetails?.collectionLogic}
              isDragOver={pin.pinKey === dragOverPinKey && isOrphanDragging}
              onEditPin={() => onEditPin(pin)}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default DraggablePinAccordion; 