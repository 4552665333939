import React, { useEffect, useRef } from 'react';
import { Modal, Box } from '@mui/material';
import { TermsAndConditions } from '../components';
import '../pages/FormsView.css';

interface TermsModalProps {
  open: boolean;
  agreed: boolean;
  onAgree: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onContinue: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, agreed, onAgree, onContinue }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Manage focus when modal opens
  useEffect(() => {
    if (open && modalRef.current) {
      // Set timeout to ensure DOM is ready
      const timer = setTimeout(() => {
        if (modalRef.current) {
          // Find the first focusable element in the modal
          const focusableElements = 
            modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
          
          if (focusableElements.length > 0) {
            (focusableElements[0] as HTMLElement).focus();
          }
        }
      }, 50);
      
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="terms-and-conditions-modal"
      aria-describedby="terms-and-conditions-description"
      disableAutoFocus={false}
      disableEnforceFocus={false}
      disablePortal={false}
      keepMounted
    >
      <Box 
        className="modal-box" 
        tabIndex={-1}
        ref={modalRef}
        role="dialog"
        aria-modal="true"
      >
        <TermsAndConditions
          agreed={agreed}
          onAgree={onAgree}
          onContinue={onContinue}
        />
      </Box>
    </Modal>
  );
};

export default TermsModal; 