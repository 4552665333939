import { db } from '../firebase/firebase';
import { arrayRemove, arrayUnion, collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { IRecordingTags, IProjectTags, IAuthorTags, ITags, ContentRating, IContentTag } from '../types';
import { IAdminTags } from '../types/AdminTags';




export const TagManager = {
  observeTagsForPin: async (pinKey: string): Promise<ITags[]> => {
    try {
      const docId = 'pinTags';
      const querySnapshot = await getDocs(collection(db, 'Tags', docId, 'tags'));
      const pinTags = querySnapshot.docs
        .filter(doc => doc.data().pinKeys.includes(pinKey))
        .map(doc => ({ id: doc.id, ...doc.data() }) as ITags);
      
      
      return pinTags;
    } catch (error) {
      console.error("Error fetching pin tags:", error);
      return [];
    }
  },

  observeTagsForRecording: async (key: string): Promise<IRecordingTags[]> => {
    try {
      const docId = 'recordingTags';
      const querySnapshot = await getDocs(collection(db, 'Tags', docId, 'tags'));
      return querySnapshot.docs
        .filter(doc => doc.data().keys.includes(key))
        .map(doc => ({ id: doc.id, ...doc.data() }) as IRecordingTags);
    } catch (error) {
      console.error("Error fetching recording tags:", error);
      return [];
    }
  },

  observeTagsForAuthor: async (authorKey: string): Promise<IAuthorTags[]> => {
    try {
      const docId = 'authorTags'; 
      const querySnapshot = await getDocs(collection(db, 'Tags', docId, 'tags'));
      return querySnapshot.docs
        .filter(doc => doc.data().authorKeys.includes(authorKey))
        .map(doc => ({ id: doc.id, ...doc.data() }) as IAuthorTags);
    } catch (error) {
      console.error("Error fetching author tags:", error);
      return [];
    }
  },

  observeTagsForProject: async (project_id: string): Promise<IProjectTags[]> => {
    try {
      // Fetch all pins for the given project
      const pinsSnapshot = await getDocs(query(collection(db, 'Pins'), where('project', '==', project_id)));
      const pinKeys = pinsSnapshot.docs.map(doc => doc.id);
  
      // Fetch tags for these pins
      const docId = 'projectTags';
      const querySnapshot = await getDocs(collection(db, 'Tags', docId, 'tags'));
      return querySnapshot.docs
        .filter(doc => doc.data().pinKeys.some((pinKey: string) => pinKeys.includes(pinKey)))
        .map(doc => ({ id: doc.id, ...doc.data() }) as IProjectTags);
    } catch (error) {
      console.error("Error fetching project tags:", error);
      return [];
    }
  },

  observeAdminTags: async (): Promise<IAdminTags[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'AdminTags')); // Change 'adminTags' to 'AdminTags'
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as IAdminTags);
    } catch (error) {
      console.error("Error fetching admin tags:", error);
      return [];
    }
  },

  observeAllTags: async (): Promise<ITags[]> => {
    try {
      const tagTypes = ['authorTags', 'recordingTags', 'pinTags', 'projectTags'];
      let allTags: ITags[] = [];

      for (const tagType of tagTypes) {
        const querySnapshot = await getDocs(collection(db, 'Tags', tagType, 'tags'));
        const tags = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as ITags);
        allTags = [...allTags, ...tags];
      }

      return allTags;
    } catch (error) {
      console.error("Error fetching all tags:", error);
      return [];
    }
  },

  addTagToPin: async (tagName: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'pinTags', 'tags', tagName);
    try {
      // Check if the tag already exists
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        // If the tag exists, update the document to include the new pinKey
        await updateDoc(tagRef, { pinKeys: arrayUnion(pinKey) });
      } else {
        // If the tag doesn't exist, create a new document with the pinKey and tagName
        await setDoc(tagRef, { pinKeys: [pinKey], tagName: tagName });
      }
    } catch (error) {
      console.error(`Error adding tag ${tagName} to pin ${pinKey}:`, error);
      throw error;
    }
  },

  addLocationTagToPin: async (pinKey: string, tagName: string): Promise<void> => {
    const pinRef = doc(db, 'Pins', pinKey);
    try {
      await updateDoc(pinRef, { 'tags.locationTags': arrayUnion(tagName) });
    } catch (error) {
      console.error(`Error adding location tag ${tagName} to pin ${pinKey}:`, error);
      throw error;
    }
  },

  addAuthorTagToAuthor: async (authorKey: string, tagName: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'authorTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        await updateDoc(tagRef, {
          authorKeys: arrayUnion(authorKey),
          pinKeys: arrayUnion(pinKey) // Add the pinKey to the document
        });
      } else {
        await setDoc(tagRef, {
          authorKeys: [authorKey],
          tagName: tagName,
          pinKeys: [pinKey] // Include the pinKey in the new document
        });
      }
    } catch (error) {
      console.error(`Error adding author tag ${tagName} to author ${authorKey} with pin ${pinKey}:`, error);
      throw error;
    }
  },
  
  addSubjectTagToRecording: async (key: string, tagName: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        await updateDoc(tagRef, {
          keys: arrayUnion(key),
          pinKeys: arrayUnion(pinKey) // Add the pinKey to the document
        });
      } else {
        await setDoc(tagRef, {
          keys: [key],
          tagName: tagName,
          pinKeys: [pinKey] // Include the pinKey in the new document
        });
      }
    } catch (error) {
      console.error(`Error adding subject tag ${tagName} to recording ${key} with pin ${pinKey}:`, error);
      throw error;
    }
  },

  updateAuthorTags: async (authorKey: string, newTags: string[], oldTags: string[], pinKey: string): Promise<void> => {
    try {
      // Reference to the author's document in the Authors Collection
      const authorRef = doc(db, 'Authors', authorKey);

      // Update the author's tags
      if (newTags.length > 0) {
        await updateDoc(authorRef, {
          'tags.authorTags': newTags
        });
        
      } else {
        // If there are no tags left, remove the tags field
        await updateDoc(authorRef, {
          'tags.authorTags': deleteField()
        });
        
      }

      // Add new tags and remove old tags from the Tags Collection
      const addedTags = newTags.filter(tag => !oldTags.includes(tag));
      const removedTags = oldTags.filter(tag => !newTags.includes(tag));


      for (const tag of addedTags) {

        const tagRef = doc(db, 'Tags', 'authorTags', 'tags', tag);
        try {
          await updateDoc(tagRef, {
            authorKeys: arrayUnion(authorKey),
            pinKeys: arrayUnion(pinKey)
          });

        } catch (error) {
          // If the tag doesn't exist, create it
          await setDoc(tagRef, {
            tagName: tag,
            authorKeys: [authorKey],
            pinKeys: [pinKey]
          });

        }
      }

      for (const tag of removedTags) {
   
        const tagRef = doc(db, 'Tags', 'authorTags', 'tags', tag);
        const tagDoc = await getDoc(tagRef);
        if (tagDoc.exists()) {
          const tagData = tagDoc.data();
          const updatedAuthorKeys = tagData.authorKeys.filter((key: string) => key !== authorKey);
          const updatedPinKeys = tagData.pinKeys.filter((key: string) => key !== pinKey);
          
          if (updatedAuthorKeys.length === 0) {
            // If no authors are left, delete the tag document
            await deleteDoc(tagRef);

          } else {
            // Update the tag document with the remaining authors and pins
            await updateDoc(tagRef, {
              authorKeys: updatedAuthorKeys,
              pinKeys: updatedPinKeys
            });

          }
        }
      }

      
    } catch (error) {
      console.error("TagManager: Error updating author tags:", error);
      if (error instanceof Error) {
        console.error("TagManager: Error message:", error.message);
        console.error("TagManager: Error stack:", error.stack);
      }
      throw error;
    }
  },

  updateRecordingTags: async (key: string, newSubjectTags: string[], oldSubjectTags: string[], newGenreTags: string[], oldGenreTags: string[], pinKey: string) => {
    // Reference to the recording's document in the Recordings Collection
    const recordingRef = doc(db, 'Recordings', key);
  
    // Update the recording's tags
    await updateDoc(recordingRef, {
      'tags.subjectTags': newSubjectTags,
      'tags.genreTags': newGenreTags
    });
  
    // Handle subject tags
    for (const tag of newSubjectTags) {
      if (!oldSubjectTags.includes(tag)) {
        const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tag);
        await updateDoc(tagRef, {
          keys: arrayUnion(key)
        }).catch(async () => {
          await setDoc(tagRef, {
            tagName: tag,
            keys: [key],
            pinKeys: [pinKey]
          });
        });
      }
    }
  
    for (const tag of oldSubjectTags) {
      if (!newSubjectTags.includes(tag)) {
        const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tag);
        await updateDoc(tagRef, {
          keys: arrayRemove(key),
          pinKeys: arrayRemove(pinKey)
        });
      }
    }
  
    // Handle genre tags
    for (const tag of newGenreTags) {
      if (!oldGenreTags.includes(tag)) {
        const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tag);
        await updateDoc(tagRef, {
          keys: arrayUnion(key)
        }).catch(async () => {
          await setDoc(tagRef, {
            tagName: tag,
            keys: [key],
            pinKeys: [pinKey]
          });
        });
      }
    }
  
    for (const tag of oldGenreTags) {
      if (!newGenreTags.includes(tag)) {
        const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tag);
        await updateDoc(tagRef, {
          keys: arrayRemove(key),
          pinKeys: arrayRemove(pinKey)
        });
      }
    }
  
  },

  removePinFromTags: async (pinKey: string): Promise<void> => {
    try {
      const tagsRef = collection(db, 'Tags', 'pinTags', 'tags');
      const querySnapshot = await getDocs(tagsRef);
      const batch = writeBatch(db); // Corrected line: Call batch() as a method
  
      querySnapshot.docs.forEach((doc) => {
        if (doc.data().pinKeys.includes(pinKey)) {
          const tagRef = doc.ref;
          batch.update(tagRef, { pinKeys: arrayRemove(pinKey) });
        }
      });
  
      await batch.commit();
    } catch (error) {
      console.error(`Error removing pin ${pinKey} from tags:`, error);
      throw error;
    }
  },

  removePinKeyFromTag: async (tagName: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'pinTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        const data = tagDoc.data();
        if (data && data.pinKeys.includes(pinKey)) {
          // Remove the pinKey from the array
          const newPinKeys = data.pinKeys.filter((key: string) => key !== pinKey);
  
          // If it's the last key, delete the document
          if (newPinKeys.length === 0) {
            await deleteDoc(tagRef);
          } else {
            // Otherwise, update the document with the new array
            await updateDoc(tagRef, { pinKeys: newPinKeys });
          }
        }
      } else {
      }
    } catch (error) {
      console.error(`Error removing pinKey ${pinKey} from tag ${tagName}:`, error);
      throw error;
    }
  },

  removeAuthorKeyFromTag: async (tagName: string, authorKey: string, pinKey: string): Promise<void> => {
    if (!tagName) {
      
      return;
    }
    const tagRef = doc(db, 'Tags', 'authorTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        const data = tagDoc.data();
        if (data) {
          // Remove the authorKey from the array
          const newAuthorKeys = data.authorKeys?.filter((key: string) => key !== authorKey) || [];
  
          // If it's the last authorKey, delete the document
          if (newAuthorKeys.length === 0) {
            await deleteDoc(tagRef);
          } else {
            // Otherwise, update the document with the new authorKeys array
            // and remove the pinKey (no need to check if it's the last one)
            await updateDoc(tagRef, { 
              authorKeys: newAuthorKeys,
              pinKeys: arrayRemove(authorKey) // Assuming you want to remove the authorKey from pinKeys
            });
          }
        }
      } else {
      }
    } catch (error) {
      console.error(`Error removing authorKey ${authorKey} from tag ${tagName}:`, error);
      throw error;
    }
  },

  removeRecordingKeyFromTag: async (tagName: string, key: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        const data = tagDoc.data();
        if (data) {
          // Remove the key from the array
          const newRecordingKeys = data.keys?.filter((key: string) => key !== key) || [];
  
          // If it's the last key, delete the document
          if (newRecordingKeys.length === 0) {
            await deleteDoc(tagRef);
          } else {
            // Otherwise, update the document with the new keys array
            // and remove the pinKey (no need to check if it's the last one)
            await updateDoc(tagRef, { 
              keys: newRecordingKeys,
              pinKeys: arrayRemove(key) // Assuming you want to remove the key from pinKeys
            });
          }
        }
      } else {
      }
    } catch (error) {
      console.error(`Error removing key ${key} from tag ${tagName}:`, error);
      throw error;
    }
  },

  removeRecordingKeyFromGenreTag: async (tagName: string, key: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tagName);
    try {
      await updateDoc(tagRef, {
        keys: arrayRemove(key),
        pinKeys: arrayRemove(pinKey)
      });
    } catch (error) {
      console.error(`Error removing key ${key} and pinKey ${pinKey} from genre tag ${tagName}:`, error);
      throw error;
    }
  },

  removePinKeyFromAuthorTag: async (tagName: string, pinKey: string): Promise<void> => {
    if (!tagName) {
      
      return;
    }
    
    const tagRef = doc(db, 'Tags', 'authorTags', 'tags', tagName);
    try {
      await updateDoc(tagRef, {
        pinKeys: arrayRemove(pinKey)
      });
    } catch (error) {
      console.error(`Error removing pinKey ${pinKey} from author tag ${tagName}:`, error);
      throw error;
    }
  },

  addRecordingKeyToGenreTag: async (tagName: string, key: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'recordingTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        await updateDoc(tagRef, {
          keys: arrayUnion(key),
          pinKeys: arrayUnion(pinKey)
        });
      } else {
        await setDoc(tagRef, {
          keys: [key],
          tagName: tagName,
          pinKeys: [pinKey]
        });
      }
    } catch (error) {
      console.error(`Error adding key ${key} and pinKey ${pinKey} to genre tag ${tagName}:`, error);
      throw error;
    }
  },

  observeAdminContentTags: async (): Promise<string[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'AdminTags'));
      const contentTags = querySnapshot.docs.map(doc => doc.data().contentTags).flat();
      return contentTags;
    } catch (error) {
      console.error("Error fetching admin content tags:", error);
      return [];
    }
  },

  getSubjectTagsForProject: async (project_id: string): Promise<string[]> => {
    try {
      // Assuming that subject tags are stored in a collection named 'ProjectTags'
      // and that each document has a field 'subjectTags' which is an array of strings
      const projectTagsRef = doc(db, 'ProjectTags', project_id);
      const projectTagsDoc = await getDoc(projectTagsRef);
  
      if (projectTagsDoc.exists()) {
        const projectTagsData = projectTagsDoc.data();
        // Assuming the subject tags are stored under a field named 'subjectTags'
        return projectTagsData.subjectTags as string[];
      } else {
        return [];
      }
    } catch (error) {
      console.error(`Error fetching subject tags for project ${project_id}:`, error);
      return [];
    }
  },

  updateProjectTags: async (project_id: string, tagName: string, pinKeys: string[]): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'projectTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        await updateDoc(tagRef, {
          pinKeys: arrayUnion(...pinKeys),
        });
      } else {
        await setDoc(tagRef, {
          tagName: tagName,
          pinKeys: pinKeys,
        });
      }
    } catch (error) {
      console.error(`Error updating or creating tag ${tagName} for project ${project_id}:`, error);
      throw error;
    }
  },
  
  removeProjectTags: async (project_id: string, tagName: string, pinKey: string): Promise<void> => {
    const tagRef = doc(db, 'Tags', 'projectTags', 'tags', tagName);
    try {
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        const tagData = tagDoc.data();
        const pinKeys = tagData.pinKeys as string[];
        if (pinKeys.includes(pinKey)) {
          // Remove the pinKey from the array
          const newPinKeys = pinKeys.filter(pk => pk !== pinKey);
          if (newPinKeys.length === 0) {
            // If no more pinKeys, delete the document
            await deleteDoc(tagRef);
          } else {
            // Otherwise, just update the document
            await updateDoc(tagRef, { pinKeys: newPinKeys });
          }
        } else {
        }
      } else {
      }
    } catch (error) {
      console.error(`Error removing pinKey ${pinKey} from tag ${tagName}:`, error);
      throw error;
    }
  },

  observeContentTags: async (): Promise<IContentTag[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Tags', 'contentTags', 'tags'));
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as IContentTag);
    } catch (error) {
      console.error("Error fetching content tags:", error);
      return [];
    }
  },

  updateContentRating: async (recordingKey: string, contentRating: ContentRating): Promise<void> => {
    try {
      // Update or create the content tag
      const tagRef = doc(db, 'Tags', 'contentTags', 'tags', contentRating);
      const tagDoc = await getDoc(tagRef);

      if (tagDoc.exists()) {
        await updateDoc(tagRef, {
          recordingKeys: arrayUnion(recordingKey)
        });
      } else {
        await setDoc(tagRef, {
          tagName: contentRating,
          recordingKeys: [recordingKey]
        });
      }

      // Remove the recording from other content tag collections
      const otherRatings = ['Adult', 'Young Adult', 'Family'].filter(rating => rating !== contentRating);
      for (const rating of otherRatings) {
        const otherTagRef = doc(db, 'Tags', 'contentTags', 'tags', rating);
        const otherTagDoc = await getDoc(otherTagRef);
        if (otherTagDoc.exists()) {
          await updateDoc(otherTagRef, {
            recordingKeys: arrayRemove(recordingKey)
          });
        }
      }
    } catch (error) {
      console.error(`Error updating content rating for recording ${recordingKey}:`, error);
      throw error;
    }
  },

  getRecordingsByContentRating: async (contentRating: ContentRating): Promise<string[]> => {
    try {
      const tagRef = doc(db, 'Tags', 'contentTags', 'tags', contentRating);
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        return tagDoc.data().recordingKeys || [];
      }
      return [];
    } catch (error) {
      console.error(`Error getting recordings for content rating ${contentRating}:`, error);
      return [];
    }
  },

  removeContentRating: async (recordingKey: string, contentRating: ContentRating): Promise<void> => {
    try {
      // Remove the content rating from the recording document
      const recordingRef = doc(db, 'Recordings', recordingKey);
      await updateDoc(recordingRef, {
        contentRating: deleteField()
      });

      // Remove the recording from the content tag collection
      const tagRef = doc(db, 'Tags', 'contentTags', 'tags', contentRating);
      await updateDoc(tagRef, {
        recordingKeys: arrayRemove(recordingKey)
      });
    } catch (error) {
      console.error(`Error removing content rating for recording ${recordingKey}:`, error);
      throw error;
    }
  },

};


