import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RecordingEditor from '../components/RecordingEditor';
import { ProjectManager } from '../models/ProjectManager';
import AuthorManager from '../models/AuthorManager';
import UserManager from '../models/UserManager';
import './FormsView.css'
import "../components/EditRecording.css";
import RecordingStore from '../stores/RecordingStore';
import authenticationStore from '../stores/AuthenticationStore';
import { IAuthor } from '../types/Author';
import { 
  PasswordForm, 
  TermsModal, 
  NotificationSnackbar, 
  LogoHeader 
} from '../components';
import useNotification from '../hooks/useNotification';

const FormsView: React.FC = observer(() => {
  const { project_id } = useParams<{ project_id: string }>();
  const [config, setConfig] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
    password: ''
  });
  const [inputPassword, setInputPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [authorData, setAuthorData] = useState<IAuthor | null>(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  
  // Loading and error state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Use notification hook
  const { 
    isOpen: snackbarOpen, 
    message: snackbarMessage, 
    severity: snackbarSeverity,
    showNotification,
    closeNotification
  } = useNotification();
  
  // Refs for tracking component mounting state and previous project ID
  const isMounted = useRef(true);
  const prevProjectId = useRef<string | undefined>(project_id);
  const mainFocusRef = useRef<HTMLDivElement>(null);

  // Safe state update function with proper type handling
  const safeSetState = useCallback((setter: Function, value: any) => {
    if (isMounted.current) {
      setter(value);
    }
  }, []);

  const fetchConfig = async () => {
    if (!isMounted.current) return;
    
    try {
      safeSetState(setIsLoading, true);
      safeSetState(setError, null);
      
      if (project_id) {
        const projectConfig = await ProjectManager.getProjectConfig(project_id);
        if (projectConfig && isMounted.current) {
          safeSetState(setConfig, projectConfig);
        } else if (isMounted.current) {
          throw new Error('Project configuration not found');
        }
      } else {
        throw new Error('Project ID is missing');
      }
    } catch (err) {
      if (!isMounted.current) return;
      
      const errorMessage = err instanceof Error ? err.message : 'Failed to load project configuration';
      safeSetState(setError, errorMessage);
      showNotification(errorMessage, 'error');
      console.error('Error fetching config:', err);
    } finally {
      if (isMounted.current) {
        safeSetState(setIsLoading, false);
      }
    }
  };

  const fetchAuthorData = async () => {
    if (!isMounted.current) return;
    
    try {
      if (authenticationStore.isAuthenticated && authenticationStore.user) {
        const user = await UserManager.getCurrentUser(authenticationStore.user.uid);
        if (user && user.userProfile.authorKey && isMounted.current) {
          const author = await AuthorManager.getAuthor(user.userProfile.authorKey);
          if (author && isMounted.current) {
            safeSetState(setAuthorData, author);
          }
        }
      }
    } catch (err) {
      if (!isMounted.current) return;
      
      console.error('Error fetching author data:', err);
      showNotification('Failed to load author data. Some features may be limited.', 'error');
    }
  };

  // Effect for component mounting/unmounting
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      // Ensure isMounted is set to false immediately to prevent any async operations
      isMounted.current = false;
    };
  }, []);

  // Effect to fetch config and author data when project_id changes
  useEffect(() => {
    // Only fetch if project_id changed or component mounted first time
    if (prevProjectId.current !== project_id) {
      fetchConfig();
      fetchAuthorData();
      prevProjectId.current = project_id;
    }
  }, [project_id, authenticationStore.isAuthenticated, authenticationStore.user]);

  // Separate effect to handle ResizeObserver error
  useEffect(() => {
    // Original ResizeObserver implementation
    const originalResizeObserver = window.ResizeObserver;
    
    try {
      // Custom ResizeObserver that handles the loop limit exceeded error
      window.ResizeObserver = class CustomResizeObserver extends originalResizeObserver {
        constructor(callback: ResizeObserverCallback) {
          super((entries, observer) => {
            // Safely call the callback in an animation frame to prevent loop limit errors
            window.requestAnimationFrame(() => {
              if (isMounted.current) {
                try {
                  callback(entries, observer);
                } catch (e) {
                  console.error('ResizeObserver error caught:', e);
                }
              }
            });
          });
        }
      };
    } catch (e) {
      console.error('Failed to override ResizeObserver:', e);
    }

    return () => {
      // Restore the original ResizeObserver when component unmounts
      window.ResizeObserver = originalResizeObserver;
    };
  }, []);

  // Focus management - returns focus to main container when modals close
  useEffect(() => {
    if (!openTermsModal && mainFocusRef.current && passwordSubmitted) {
      setTimeout(() => {
        if (mainFocusRef.current) {
          mainFocusRef.current.focus();
        }
      }, 100);
    }
  }, [openTermsModal, passwordSubmitted]);

  const handlePasswordSubmit = async (password: string) => {
    if (!isMounted.current) return;
    
    try {
      safeSetState(setIsLoading, true);
      safeSetState(setError, null);
      safeSetState(setInputPassword, password);
      
      if (!password.trim()) {
        throw new Error('Please enter a password');
      }

      // Fetch fresh config to ensure we have the latest password
      if (project_id) {
        const projectConfig = await ProjectManager.getProjectConfig(project_id);
        if (!projectConfig) {
          throw new Error('Project configuration not found');
        }
        
        // Compare with the fetched config directly instead of the state
        if (password !== projectConfig.password) {
          throw new Error('Incorrect password. Please try again.');
        }
        
        // Only update the state if validation passes and component is still mounted
        if (isMounted.current) {
          safeSetState(setConfig, projectConfig);
          safeSetState(setPasswordSubmitted, true);
          safeSetState(setOpenTermsModal, true);
          showNotification('Password accepted', 'success');
        }
      } else {
        throw new Error('Project ID is missing');
      }
    } catch (err) {
      if (!isMounted.current) return;
      
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      safeSetState(setError, errorMessage);
      safeSetState(setPasswordSubmitted, false);
      showNotification(errorMessage, 'error');
    } finally {
      if (isMounted.current) {
        safeSetState(setIsLoading, false);
      }
    }
  };

  const isPasswordCorrect = () => {
    return inputPassword === config.password;
  };

  const handleRecordingAdded = () => {
    showNotification('Recording added successfully', 'success');
  };

  const handleSave = async (updatedRecording: any) => {
    if (!isMounted.current) return;
    
    try {
      safeSetState(setIsLoading, true);
      
      const user = authenticationStore.user;
      
      // Create a sanitized version of the recording
      const sanitizedRecording = { ...updatedRecording };
      
      // Add author if available
      if (user && authorData) {
        sanitizedRecording.authorId = authorData.authorKey;
      }

      // Handle file data - remove undefined file properties
      if (sanitizedRecording.file) {
        Object.keys(sanitizedRecording.file).forEach(key => {
          if (sanitizedRecording.file[key] === undefined) {
            delete sanitizedRecording.file[key];
          }
        });
        
        // If file object is empty, remove it entirely
        if (Object.keys(sanitizedRecording.file).length === 0) {
          delete sanitizedRecording.file;
        }
      }

      if (isMounted.current) {
        await RecordingStore.addRecording(sanitizedRecording);
      }
      // Don't show success message here, let RecordingEditor handle it
    } catch (error) {
      console.error('Error in save process:', error);
      throw error; // Let RecordingEditor handle the error
    } finally {
      if (isMounted.current) {
        safeSetState(setIsLoading, false);
      }
    }
  };

  const handleTermsAgreement = (event: React.ChangeEvent<HTMLInputElement>) => {
    safeSetState(setTermsAgreed, event.target.checked);
  };

  const handleContinueAfterTerms = () => {
    if (isMounted.current) {
      safeSetState(setTermsAgreed, true);
      safeSetState(setOpenTermsModal, false);
      showNotification('Terms accepted. You can now proceed with the form.', 'success');
    }
  };

  return (
    <div 
      className="forms-view-container" 
      tabIndex={-1} 
      ref={mainFocusRef}
      aria-live="polite"
    >
      <LogoHeader 
        logoSrc="/assets/icons/overhearlight.png"
        altText="Overhear Light"
      />

      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={closeNotification}
      />

      {!passwordSubmitted || !isPasswordCorrect() ? (
        <PasswordForm
          onSubmit={handlePasswordSubmit}
          isLoading={isLoading}
          error={error}
        />
      ) : (
        <RecordingEditor
          onClose={() => {}} // Handle closing if needed
          recordingKey={undefined}
          recording={undefined}
          sourceView="Form"
          config={config}
          project_id={project_id}
          onRecordingAdded={handleRecordingAdded}
          onSave={handleSave}
          prefillAuthorData={authorData}
        />
      )}

      <TermsModal
        open={openTermsModal}
        agreed={termsAgreed}
        onAgree={handleTermsAgreement}
        onContinue={handleContinueAfterTerms}
      />
    </div>
  );
});

export default FormsView;