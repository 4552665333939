// src/components/FormLogin.tsx

import { FunctionComponent, useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Button,
  Box,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from "react-router-dom";
import "./FormLogin.css";
import { ProjectManager } from "../models";
import { IProject } from "../types";
import { useProjectContext } from '../contexts/ProjectContext';
import { auth } from '../firebase/firebase';
import { sendPasswordResetEmail } from "firebase/auth";

interface FormLoginProps {
  loginType: 'admin' | 'organisation';
  adminType: 'superAdmin' | 'projectAdmin';
  orgType: 'orgAdmin' | 'orgModerator';
  onAdminTypeChange: (type: 'superAdmin' | 'projectAdmin') => void;
  onOrgTypeChange: (type: 'orgAdmin' | 'orgModerator') => void;
  onSubmit: (data: { email: string; password: string; accessCode?: string; projectId?: string }) => Promise<void>;
  loading: boolean;
  error?: Error | null;
}

const FormLogin: FunctionComponent<FormLoginProps> = ({ 
  loginType,
  adminType,
  orgType,
  onAdminTypeChange,
  onOrgTypeChange,
  onSubmit,
  loading,
  error
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [accessCode, setAccessCode] = useState<string>('');
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const { setSelectedProject: setGlobalSelectedProject } = useProjectContext();

  useEffect(() => {
    const fetchProjects = async () => {
      const projects = await ProjectManager.observeProjects();
      setProjects(projects);
    };

    if (loginType === 'admin' && adminType === 'projectAdmin') {
      fetchProjects();
    }
  }, [loginType, adminType]);

  const handleProjectChange = (event: SelectChangeEvent<string>) => {
    setSelectedProject(event.target.value);
    setGlobalSelectedProject(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit({ 
      email, 
      password, 
      accessCode,
      projectId: loginType === 'admin' && adminType === 'projectAdmin' ? selectedProject : undefined
    });
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email. Please try again.');
    }
  };

  const renderTitle = () => {
    if (loginType === 'admin') {
      return adminType === 'superAdmin' ? 'Super Admin Login' : 'Project Admin Login';
    } else {
      return orgType === 'orgAdmin' ? 'Organisation Admin Login' : 'Organisation Moderator Login';
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <Typography variant="h5" gutterBottom>
        {renderTitle()}
      </Typography>

      {loginType === 'admin' && (
        <ToggleButtonGroup
          value={adminType}
          exclusive
          onChange={(_, value) => value && onAdminTypeChange(value)}
          fullWidth
          sx={{ mb: 3 }}
        >
          <ToggleButton value="superAdmin">Super Admin</ToggleButton>
          <ToggleButton value="projectAdmin">Project Admin</ToggleButton>
        </ToggleButtonGroup>
      )}

      {loginType === 'organisation' && (
        <ToggleButtonGroup
          value={orgType}
          exclusive
          onChange={(_, value) => value && onOrgTypeChange(value)}
          fullWidth
          sx={{ mb: 3 }}
        >
          <ToggleButton value="orgAdmin">Organisation Admin</ToggleButton>
          <ToggleButton value="orgModerator">Organisation Moderator</ToggleButton>
        </ToggleButtonGroup>
      )}

      {loginType === 'admin' && adminType === 'projectAdmin' && (
        <Select 
          sx={{
            backgroundColor: "var(--color-lightsteelblue)",
            alignSelf: "stretch",
            borderRadius: "var(--br-8xs)",
            padding: "0 var(--padding-2xl)",
            boxSizing: "border-box",
            mb: 2,
            '& .MuiOutlinedInput-root': {
              fontSize: "var(--font-size-base)",
              fontFamily: "var(--font-manrope)",
              border: 0,
              fontWeight: 500,
              height: "50px",
              display: "flex",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
          value={selectedProject}
          onChange={handleProjectChange}
          fullWidth
          required={loginType === 'admin' && adminType === 'projectAdmin'}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Choose your project
          </MenuItem>
          {projects.map((project) => (
            <MenuItem 
              key={project.project_id} 
              value={project.project_id}
            >
              {project.projectName}
            </MenuItem>
          ))}
        </Select>
      )}

      {loginType === 'organisation' && orgType === 'orgModerator' && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Access Code
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Enter access code"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            disabled={loading}
          />
          
          <Typography variant="body1" align="center" sx={{ my: 2 }}>
            OR
          </Typography>
        </Box>
      )}

      <TextField
        fullWidth
        margin="normal"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={!accessCode}
        disabled={loading}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        margin="normal"
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required={!accessCode}
        disabled={loading}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />

      {loginType === 'admin' && (
        <a className="forgot-password-container" onClick={handleForgotPassword}>
          <b className="forgot-password">Forgot password?</b>
        </a>
      )}

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={loading || 
          (!accessCode && (!email || !password)) || 
          (loginType === 'admin' && adminType === 'projectAdmin' && !selectedProject)}
        sx={{ mt: 2, height: 48 }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CircularProgress size={24} color="inherit" />
            <span>Logging in...</span>
          </Box>
        ) : (
          'Login'
        )}
      </Button>

      {loginType === 'organisation' && orgType === 'orgAdmin' && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/register-org">Register a New Organisation</Link>
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mt: 2 }} align="center">
          {error.message}
        </Typography>
      )}
    </form>
  );
};

export default FormLogin;
