import React, { useState, useRef, useEffect } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import '../pages/FormsView.css';

interface PasswordFormProps {
  onSubmit: (password: string) => void;
  isLoading: boolean;
  error: string | null;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef<HTMLInputElement>(null);

  // Focus on the password input when component mounts or when error changes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, [error]); // Re-focus when error changes

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(password);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div 
      className='password-container'
      role="form"
      aria-labelledby="form-heading"
    >
      <h2 id="form-heading" className="visually-hidden">Form Access</h2>
      <label htmlFor="password-input">Enter Password to Access Form:</label>
      <TextField
        id="password-input"
        inputRef={passwordInputRef}
        type="password"
        onChange={handlePasswordChange}
        value={password}
        onKeyDown={handleKeyDown}
        error={!!error}
        helperText={error}
        disabled={isLoading}
        fullWidth
        inputProps={{
          'aria-label': 'Password',
          'aria-required': 'true',
          'aria-invalid': !!error,
          'aria-describedby': error ? 'password-error' : undefined,
          autoComplete: 'off'
        }}
      />
      {error && <div id="password-error" className="sr-only">{error}</div>}
      <button 
        onClick={handleSubmit}
        disabled={isLoading}
        aria-label="Submit"
        aria-busy={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Submit'}
      </button>
    </div>
  );
};

export default PasswordForm; 