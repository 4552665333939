import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ListHeader from './ListHeader';
import DraggablePinAccordion from './DraggablePinAccordion';
import { IPin } from '../types';
import { CollectionLogic } from '../types';

interface PinColumnViewProps {
  sortedPins: IPin[];
  pinHeaders: string[];
  dragOverPinKey: string | null;
  isOrphanDragging: boolean;
  selectedProjectDetails: any; // Replace 'any' with the actual type if available
  onEditPin: (pin: IPin) => void;
}

export const PinColumnView: React.FC<PinColumnViewProps> = ({
  sortedPins,
  pinHeaders,
  dragOverPinKey,
  isOrphanDragging,
  selectedProjectDetails,
  onEditPin,
}) => {
  return (
    <div className="pin-column">
      <ListHeader headers={pinHeaders} columnCount={pinHeaders.length} />
      <Droppable droppableId="droppablePins" direction="vertical">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={`dragstyles ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
            {sortedPins.length > 0
              ? sortedPins.map((pin, index) => (
                  <DraggablePinAccordion
                    key={pin.pinKey}
                    pin={pin}
                    index={index}
                    isDragDisabled={
                      isOrphanDragging || 
                      selectedProjectDetails?.collectionLogic === CollectionLogic.Branching
                    }
                    dragOverPinKey={dragOverPinKey}
                    isOrphanDragging={isOrphanDragging}
                    selectedProjectDetails={selectedProjectDetails}
                    totalPins={sortedPins.length}
                    onEditPin={onEditPin}
                  />
                ))
              : <div className="firstPin">Add your first Pin.</div>
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default PinColumnView;