import { FunctionComponent, useState, useEffect, useCallback } from "react";
import AllDataStore from "../stores/AllDataStore";
import { AdminNav, ButtonRow, Icon, RecordingEditor, PinEditor, TransitPinView, PinColumnView } from "../components";
import { useNavigate } from "react-router-dom";
import "./ListPages.css";
import { IPin, IPinWithRecordings, IRecordingWithAuthor } from "../types";
import AllDataManager from "../models/AllDataManager";
import { PinManager, ProjectManager, RecordingManager } from "../models";
import RecordingStore from "../stores/RecordingStore";
import { observer } from "mobx-react-lite";
import pinStore from "../stores/PinStore";
import { useAdminNav } from "../hooks/useAdminNav";
import { DragDropContext, DropResult, DragStart, DragUpdate } from 'react-beautiful-dnd';
import Sidebar from "../components/Sidebar";
import PortalPopup from "../components/PortalPopup";
import { handleSavePin } from "../utilities/handleSavePin"; 
import { handleDragEnd } from '../utilities/dragUtils';
import { CollectionLogic } from "../types/Project";
import { sortPins } from '../utilities/pinOrderUtils';

const PinListView: FunctionComponent = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isRecordingEditorOpen, setIsRecordingEditorOpen] = useState(false);
  const [isPinEditorOpen, setIsPinEditorOpen] = useState(false);
  const [selectedPin, setSelectedPin] = useState<IPin | null>(null);
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();
  const selectedProjectDetails = projects.find(project => project.project_id === selectedProject);
  const sortedPins = sortPins(Array.from(pinStore.pins.values()), selectedProjectDetails?.collectionLogic);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOrphanDragging, setIsOrphanDragging] = useState(false);
  const [dragOverPinKey, setDragOverPinKey] = useState<string | null>(null);
  const [firstPinAlbumKey, setFirstPinAlbumKey] = useState<string | null>(null);
  const [transitRecordings, setTransitRecordings] = useState<IRecordingWithAuthor[]>([]);

  const pinHeaders = (() => {
    switch (selectedProjectDetails?.collectionLogic) {
      case CollectionLogic.Sequential:
        return ['', '', 'Live', 'Pin Name', 'Order', 'Downloads', 'Type', '', ''];
      case CollectionLogic.Branching:
        return ['', '', 'Live', 'Pin Name', 'Branch', 'Downloads', 'Type', '', ''];
      case CollectionLogic.Regular:
      default:
        return ['', '', 'Live', 'Pin Name', 'Downloads', 'Type', '', '', ''];
    }
  })();

  useEffect(() => {
    const fetchTransitAlbumKey = async () => {
      if (selectedProjectDetails?.isTransitProjectEnabled) {
        const albumKey = await ProjectManager.getTransitAlbumKey(selectedProject);
        setFirstPinAlbumKey(albumKey);
      }
    };

    fetchTransitAlbumKey();
  }, [selectedProject, selectedProjectDetails?.isTransitProjectEnabled]);

  const handleSavePinWrapper = async (pin: IPin) => {
    if (selectedProjectDetails?.isTransitProjectEnabled && !firstPinAlbumKey && typeof pin.albumKey === 'string') {
      setFirstPinAlbumKey(pin.albumKey);
      await ProjectManager.setTransitAlbumKey(selectedProject, pin.albumKey);
    }
    await handleSavePin(pin, selectedProject, closePinEditor);
  };

  const closeSidebar = () => {
    setIsDrawerOpen(false);
  };

  const onDragStart = (start: DragStart) => {
    setIsOrphanDragging(start.source.droppableId === "sidebar");
  };

  const onDragUpdate = (update: DragUpdate) => {
    const { destination } = update;
    if (destination && destination.droppableId === "droppablePins") {
      setDragOverPinKey(sortedPins[destination.index].pinKey);
    } else {
      setDragOverPinKey(null);
    }
  };


  useEffect(() => {
    const fetchPins = async () => {
      setIsLoading(true);
      try {
        const fetchedPins: IPinWithRecordings[] = await AllDataManager.observeProjectData(selectedProject);

        const projectPins = fetchedPins.filter(pin => pin.project === selectedProject);

        AllDataStore.setPins(projectPins);
        const allRecordings = projectPins.flatMap(pin => pin.recordings);
        RecordingStore.setRecordings(allRecordings);

        pinStore.setPins(projectPins.map(pin => ({
          ...pin,
          recordings: [],
        })));

        setIsLoading(false);
      } catch (error) {
        setError(error as Error);
        setIsLoading(false);
      }
    }

    fetchPins();
  }, [selectedProject]);

  useEffect(() => {
    const fetchTransitRecordings = async () => {
      if (selectedProjectDetails?.isTransitProjectEnabled) {
        setIsLoading(true);
        try {
          const recordings = await RecordingManager.fetchRecordingsForTransitProject(selectedProject);
          RecordingStore.setTransitRecordings(selectedProject, recordings);
          setTransitRecordings(RecordingStore.getTransitRecordings(selectedProject));
        } catch (error) {
          console.error("Error fetching transit recordings:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTransitRecordings();
  }, [selectedProject, selectedProjectDetails]);

  useEffect(() => {
    // This will now react whenever the pins Map changes, which should be after setPins is called
  }, [pinStore.pins, selectedProject]);

  const openRecordingEditor = () => {
    setIsRecordingEditorOpen(true);
  };

  const closeRecordingEditor = () => {
    setIsRecordingEditorOpen(false);
  };

  const openPinEditor = (pin?: IPin) => {
    setSelectedPin(pin || null);
    setIsPinEditorOpen(true);
  };

  const closePinEditor = () => {
    setIsPinEditorOpen(false);
    setSelectedPin(null);
  };

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={(result) => handleDragEnd(
        result, 
        sortedPins, 
        setDragOverPinKey, 
        setIsOrphanDragging, 
        closeSidebar,
        selectedProjectDetails?.collectionLogic
      )}
      onDragUpdate={onDragUpdate}
    >
      <div className="list-page" style={{ marginTop: '80px' }}>
        <AdminNav
          imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
          projectName={projectName}
          userRole={userRole}
          projects={projects}
          selectedProject={selectedProject}
          onProjectChange={onProjectChange}
        />

<ButtonRow
          settingsIcon={<Icon icon="settings" size={24} />}
          showLocationInput={false}
          showAddNewPinButton={true}
          handleMapViewClick={() => navigate("/map-view")}
          handleListViewClick={() => navigate("/pin-list-view")}
          defaultView="list"
          userRole={userRole}
          selectedProjectDetails={selectedProjectDetails} // Add this
        />

        {!selectedProjectDetails?.isTransitProjectEnabled && (
          <Sidebar
            selectedProject={selectedProject}
            closeSidebar={closeSidebar}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        )}

        {selectedProjectDetails?.isTransitProjectEnabled ? (
          <TransitPinView
            sortedPins={sortedPins}
            transitRecordings={transitRecordings}
            isLoading={isLoading}
            pinHeaders={pinHeaders}
            dragOverPinKey={dragOverPinKey}
            isOrphanDragging={isOrphanDragging}
            selectedProjectDetails={selectedProjectDetails}
            onEditPin={openPinEditor}
          />
        ) : (
          <PinColumnView
            sortedPins={sortedPins}
            pinHeaders={pinHeaders}
            dragOverPinKey={dragOverPinKey}
            isOrphanDragging={isOrphanDragging}
            selectedProjectDetails={selectedProjectDetails}
            onEditPin={openPinEditor}
          />
        )}

        {isPinEditorOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top right"
            onOutsideClick={closePinEditor}
          >
            <PinEditor
              onClose={closePinEditor}
              onSave={handleSavePinWrapper}
              pinData={selectedPin}
              firstPinAlbumKey={firstPinAlbumKey}
            />
          </PortalPopup>
        )}

        {isRecordingEditorOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top right"
            onOutsideClick={closeRecordingEditor}
          >
            <RecordingEditor
              onClose={closeRecordingEditor}
              recordingKey={undefined}
              recording={undefined}
              pinKey={undefined}
              pinType={undefined}
              albumKey={firstPinAlbumKey}
              sourceView="PinList"
              onRecordingAdded={() => {
                RecordingStore.fetchRecordingsForPin(selectedProject);
              }}
              config={{
                locationInfo: false,
                authorInfo: true,
                recordingInfo: true,
                uploadFields: true,
              }}
              skipAuthCheck={true}
            />
          </PortalPopup>
        )}
      </div>
    </DragDropContext>
  );
});

export default PinListView;