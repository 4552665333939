import { useState, useCallback, useRef, useEffect } from 'react';

type Severity = 'success' | 'error' | 'info' | 'warning';

interface UseNotificationReturn {
  isOpen: boolean;
  message: string;
  severity: Severity;
  showNotification: (message: string, severity?: Severity) => void;
  closeNotification: () => void;
}

export const useNotification = (): UseNotificationReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>('info');
  const isMounted = useRef(true);
  
  // Handle component mounting/unmounting properly
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
    };
  }, []);
  
  // Safer way to manage the beforeunload event
  useEffect(() => {
    const handleBeforeUnload = () => {
      isMounted.current = false;
    };
    
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', handleBeforeUnload);
      
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);
  
  const showNotification = useCallback((newMessage: string, newSeverity: Severity = 'info') => {
    if (isMounted.current) {
      setMessage(newMessage);
      setSeverity(newSeverity);
      setIsOpen(true);
    }
  }, []);
  
  const closeNotification = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(false);
    }
  }, []);
  
  return {
    isOpen,
    message,
    severity,
    showNotification,
    closeNotification
  };
};

export default useNotification; 